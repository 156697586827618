import * as React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { AllTopics } from "../components/AllTopics";

const NotFoundPage = () => {
	const { t } = useTranslation();
	return (
		<Layout>
			<div className="section about-gecko-section bg-stone-200 w-full shadow-inner">
				<div className="gecko-wrapper py-8 lg:py-20 mx-auto w-2/3 lg:w-1/3">
					<h1 className="uppercase text-center text-6xl text-stone-400">
						{t("common:notFound.notFoundTitle")}
					</h1>
					<p>
          {t("common:notFound.notFoundPara")}
					</p>
				</div>
			</div>
			
			<div className="section all-topics-section pt-10">
				<div className="gecko-wrapper">
        <h1 className="text-center text-4xl py-8">
						<Link className="hover:text-gecko-red text-stone-400 uppercase" to='/'>{t("common:notFound.backHome")}</Link>
					</h1>
          <h1 className="text-center text-xl py-8 text-stone-400">
          {t("common:notFound.or")}
					</h1>
					<h1 className="uppercase text-center text-2xl py-8 text-stone-400">
          {t("common:notFound.browseByTopic")}
					</h1>
					<AllTopics />
				</div>
			</div>
		</Layout>
	);
};

export default NotFoundPage;

export const pageQuery = graphql`
	query NotFoundTemplate($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
